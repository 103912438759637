.App {
  background: linear-gradient(
    rgba(255, 204, 253, 0.7),
    rgba(255, 218, 164, 0.4)
  );
  height: 100vh;
  overflow: hidden;
  position: relative;
}
.bg > div {
  position: absolute;
  z-index: -1;
  filter: blur(150px);
  -o-filter: blur(150px);
  -ms-filter: blur(150px);
  -moz-filter: blur(150px);
  -webkit-filter: blur(150px);
}
.bg-ball-1 {
  background: rgba(210, 194, 255, 1);
  width: 100vh;
  height: 100vh;
  left: -10vw;
  top: -40vh;
}
.bg-ball-2 {
  background: rgba(255, 194, 242, 1);
  width: 130vh;
  height: 130vh;
  right: -10vw;
  top: -50vh;
}
.bg-ball-3 {
  background: rgba(253, 186, 186, 1);
  width: 80vh;
  height: 80vh;
  left: -10vw;
  bottom: -20vh;
}
.ninjas {
  position: absolute;
  background: linear-gradient(
      -217deg,
      rgba(255, 204, 253, 0.7),
      rgba(203, 137, 255, 0.6)
    ),
    url("ninja.png");
  width: 96px;
  height: 101px;
  border-radius: 100%;
  animation: bobUpAndDown 6s linear infinite;
}
.ninja-1 {
  bottom: 20%;
  left: -100px;
  transform: translateZ(1px) scale(3);
  box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.5);
  opacity: 0.4;
}
.ninja-2 {
  right: -30px;
  bottom: 60px;
  transform: scale(1.75);
  box-shadow: -4px 4px 16px rgba(0, 0, 0, 0.5);
  opacity: 0.8;
  animation-duration: 3s;
}
.ninja-3 {
  right: -10px;
  top: -90px;
  transform: scale(5);
  box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.4);
  z-index: -1;
  opacity: 0.3;
  animation-duration: 10s;
}
.settings {
  border: 1px solid white;
  border-radius: 100%;
  background: linear-gradient(
    to bottom right,
    rgba(255, 255, 255, 0.7),
    rgba(255, 255, 255, 0.1)
  );
  cursor: pointer;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.12);
  font-size: 4rem;
  position: absolute;
  left: 20px;
  top: 20px;
  width: 5rem;
  height: 5rem;
  display: inline-block;
  padding: 0;
  width: 6rem;
  height: 6rem;
  line-height: 6rem;
  font-weight: lighter !important;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  opacity: 0.5;
  transition: 0.8s;
}
.settings:hover {
  opacity: 1;
  transform: rotate(360deg);
}
.time {
  position: absolute;
  border: 1px solid white;
  border-radius: 64px;
  padding: 1rem;
  font-size: 48px;
  left: 50%;
  top: 20px;
  ms-transform: translateX(-50%);
  transform: translateX(-50%);
  background: linear-gradient(
    -217deg,
    rgba(255, 255, 255, 0.7),
    rgba(255, 255, 255, 0.1)
  );
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.12);
}
.time > p {
  padding: 0px;
  margin: 0px;
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.5));
  background: linear-gradient(
    rgba(227, 0, 50, 0.901),
    rgba(142, 0, 202, 0.6042)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.frame {
  border: 2px solid white;
  border-radius: 64px;
  background: linear-gradient(
    -217deg,
    rgba(255, 255, 255, 0.7),
    rgba(255, 255, 255, 0.1)
  );
  width: min(900px, 80%);
  height: 500px;
  margin: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.12);
  z-index: 0;
}
.timer-text-top {
  text-align: center;
  padding: 0px;
  margin: auto;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 96px;
  color: rgba(112, 0, 255, 1);
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.5));
}
span {
  background: linear-gradient(
    rgba(227, 0, 50, 0.901),
    rgba(142, 0, 202, 0.6042)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.timer-text-bottom {
  margin: 0;
  padding: 0;
  padding-bottom: 32px;
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 0;
  font-size: 48px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.8));
}
.ocean {
  height: 100px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  border-radius: 0 0 64px 64px;
}
.wave {
  mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 800 88.7'%3E%3Cpath d='M800 56.9c-155.5 0-204.9-50-405.5-49.9-200 0-250 49.9-394.5 49.9v31.8h800v-.2-31.6z' fill='%23003F7C'/%3E%3C/svg%3E");
  background: linear-gradient(
    rgba(0, 194, 255, 0.8) 0%,
    rgba(0, 87, 255, 0.5) 35%,
    rgba(68, 56, 255, 0.3) 70%,
    rgba(189, 0, 255, 0.4) 90%,
    rgba(253, 85, 85, 0.5) 100%
  );
  position: absolute;
  width: 200%;
  height: 100%;
  animation: wave 10s -3s linear infinite;
  transform: translate3d(0, 0, 0);
  opacity: 0.8;
}
.wave:nth-of-type(2) {
  bottom: 0;
  animation: wave 18s linear reverse infinite;
  opacity: 0.5;
}
.wave:nth-of-type(3) {
  bottom: 0;
  animation: wave 20s -1s linear infinite;
  opacity: 0.5;
}
.settings-frame {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
.settings-frame > p {
  width: 100%;
  font-size: 48px;
  text-align: center;
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.5));
  background: linear-gradient(
    rgba(227, 0, 50, 0.901),
    rgba(142, 0, 202, 0.6042)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.settings-frame-change {
  padding: 0px;
  margin: 0px;
  font-size: 96px;
  color: rgba(112, 0, 255, 1);
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.5));
}
.settings-frame-change > p {
  text-align: center;
}
.settings-frame-change > input {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  border: 1px solid white;
  border-radius: 64px;
  background: rgba(255, 255, 255, 0);
  font-size: 48px;
  text-align: center;
  width: 12rem;
  background: linear-gradient(
    rgba(227, 0, 50, 0.901),
    rgba(142, 0, 202, 0.6042)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.credits {
  position: absolute;
  border: 1px solid white;
  border-radius: 64px;
  padding: 1rem;
  font-size: 48px;
  right: 20px;
  bottom: 20px;
  background: linear-gradient(
    -217deg,
    rgba(255, 255, 255, 0.7),
    rgba(255, 255, 255, 0.1)
  );
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.12);
}
.credits > p {
  font-size: 32px;
  padding: 0px;
  margin: 0px;
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.5));
  background: linear-gradient(
    rgba(227, 0, 50, 0.901),
    rgba(142, 0, 202, 0.6042)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@keyframes wave {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-25%);
  }
  100% {
    transform: translateX(-50%);
  }
}
@keyframes bobUpAndDown {
  0%,
  100% {
    transform-origin: 50% 25px;
  }
  50% {
    transform-origin: 50% -25px;
  }
}
